import Vue from 'vue';
import ElementUI, { Loading, Message } from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';

// 加入 Google Analytics 追蹤碼
const gaScript = document.createElement('script');
gaScript.async = true;
gaScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-JL98KZY85S'; // 請替換為您的 GA ID
document.head.appendChild(gaScript);

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag('js', new Date());
gtag('config', 'G-JL98KZY85S'); // 請替換為您的 GA ID

Vue.use(ElementUI);
Vue.use(Loading.directive);
Vue.prototype.$message = Message;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
