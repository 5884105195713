<template>
  <div class="camp-search">
    <h1 class="page-title">台灣露營地搜尋</h1>
    <el-card class="search-card">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="關鍵字">
          <el-autocomplete
            v-model="searchForm.keyword"
            :fetch-suggestions="querySearch"
            placeholder="請輸入關鍵字"
            class="keyword-input"
            @keyup.enter.native="handleSearch"
            @select="handleSearch"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="地區">
          <el-select v-model="searchForm.area" placeholder="請選擇地區" @change="handleAreaChange">
            <el-option label="北部" value="北部"></el-option>
            <el-option label="中部" value="中部"></el-option>
            <el-option label="南部" value="南部"></el-option>
            <el-option label="東部" value="東部"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="城市">
          <el-select v-model="searchForm.city" placeholder="請選擇城市" :disabled="!searchForm.area">
            <el-option
              v-for="city in cityOptions"
              :key="city"
              :label="city"
              :value="city"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="searchForm.useAltitude" @change="handleAltitudeSwitch">
            篩選海拔
          </el-checkbox>
        </el-form-item>
        <el-form-item v-if="searchForm.useAltitude">
          <el-input-number
            v-model="searchForm.altitude"
            :min="1"
            :max="3000"
            placeholder="請輸入海拔"
            @change="handleAltitudeChange"
          ></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" :loading="loading">搜尋</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-card class="table-card">
      <div v-loading="loading" element-loading-text="搜尋中...">
        <el-table
          v-if="!isMobile"
          :data="tableData"
          style="width: 100%"
          border
          row-key="id"
          @row-click="showDetail"
        >
          <el-table-column prop="name" label="營地名稱" min-width="150">
            <template #default="scope">
              {{ scope.row.name }}
            </template>
          </el-table-column>
          <el-table-column label="營地來源" width="150">
            <template #default="scope">
              <el-link
                type="primary"
                @click.stop="openCampUrl(scope.row)"
                target="_blank"
                :underline="false"
              >
                {{ getSiteName(scope.row.site) }} {{ getCampUrl(scope.row) ? '🔗' : '' }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column prop="area" label="地區" width="100"></el-table-column>
          <el-table-column prop="city" label="城市" width="120"></el-table-column>
          <el-table-column prop="district" label="鄉鎮" width="120"></el-table-column>
          <el-table-column label="價格" width="150">
            <template #default="scope">
              <div class="price-info">
                <template v-if="isSamePrice(scope.row.spaces)">
                  <div>價格: {{ getPriceRange(scope.row.spaces, 'price_weekday') }}</div>
                </template>
                <template v-else>
                  <div>平日: {{ getPriceRange(scope.row.spaces, 'price_weekday') }}</div>
                  <div>週末: {{ getPriceRange(scope.row.spaces, 'price_weekend') }}</div>
                  <div>連假: {{ getPriceRange(scope.row.spaces, 'price_holiday') }}</div>
                </template>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="海拔" width="100">
            <template #default="scope">
              {{ getAltitudeDisplay(scope.row.altitude) }}
            </template>
          </el-table-column>
          <el-table-column label="地址" min-width="200">
            <template #default="scope">
              <el-link
                type="primary"
                @click.stop="openGoogleMaps(scope.row)"
                target="_blank"
                :underline="false"
              >
                {{ scope.row.address }}
              </el-link>
            </template>
          </el-table-column>
          <el-table-column label="標籤" min-width="200">
            <template #default="scope">
              <div class="tags-container">
                <el-tag
                  v-for="tag in getUniqueTags(scope.row.tags)"
                  :key="tag"
                  size="small"
                  class="mx-1 tag-item"
                >
                  {{ tag }}
                </el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="詳細資訊" width="120">
            <template #default="scope">
              <el-button type="text" @click.stop="showDetail(scope.row)">查看詳情</el-button>
            </template>
          </el-table-column>
        </el-table>

        <div v-else class="mobile-cards">
          <el-card v-for="item in tableData" :key="item.id" class="camp-card">
            <div slot="header" class="card-header">
              <a :href="item.link" target="_blank" class="camp-link">{{ item.name }}</a>
            </div>
            <div class="card-content">
              <div class="card-row">
                <span class="label">營地來源：</span>
                <a :href="item.link" target="_blank" class="camp-link">{{ getSiteName(item.site) }}</a>
              </div>
              <div class="card-row">
                <span class="label">海拔：</span>
                <span>{{ getAltitudeDisplay(item.altitude) }}</span>
              </div>
              <div class="card-row">
                <span class="label">標籤：</span>
                <div class="tags-container">
                  <el-tag
                    v-for="tag in getUniqueTags(item.tags)"
                    :key="tag"
                    size="small"
                    class="tag-item"
                  >
                    {{ tag }}
                  </el-tag>
                </div>
              </div>
              <div class="card-actions">
                <el-button type="text" @click="showDetail(item)">查看詳情</el-button>
              </div>
            </div>
          </el-card>
        </div>

        <div class="pagination-container">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[5, 10, 20, 50]"
            :page-size="searchForm.limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>

    <el-dialog
      :title="currentCamp.name"
      :visible.sync="dialogVisible"
      width="90%"
      custom-class="camp-detail-dialog"
      :before-close="handleClose"
      append-to-body
    >
      <div class="dialog-content">
        <div class="dialog-layout">
          <div class="dialog-left">
            <div v-if="currentCamp.related_videos && currentCamp.related_videos.length > 0" class="related-videos">
              <h4>相關影片</h4>
              <div class="video-list">
                <div v-for="video in currentCamp.related_videos.slice(0, 3)" :key="video.id" class="video-item">
                  <a :href="video.url" target="_blank" class="video-link">
                    <img :src="video.thumbnail" :alt="video.title" class="video-thumbnail">
                    <div class="video-info">
                      <div class="video-title">{{ video.title }}</div>
                      <div class="video-channel">{{ video.channel }}</div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div v-if="currentCamp.video_ids && currentCamp.video_ids.length > 0" class="video-container">
              <div v-for="videoId in currentCamp.video_ids.slice(0, 3)" :key="videoId" class="video-wrapper">
                <iframe
                  :src="`https://www.youtube.com/embed/${videoId}`"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                  allowfullscreen
                  :title="`YouTube video ${videoId}`"
                  class="youtube-video"
                ></iframe>
              </div>
            </div>
          </div>
          <div class="dialog-right">
            <h4>營地描述</h4>
            <pre class="description-content">{{ currentCamp.description || '無描述' }}</pre>
            <h4>注意事項</h4>
            <pre class="description-content">{{ currentCamp.guideline || '無注意事項' }}</pre>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios';
import { API_ENDPOINTS } from '@/config/api';
import { getToken } from '@/utils/token';

const cityMap = {
  北部: ['台北市', '新北市', '桃園市', '新竹市', '新竹縣', '基隆市'],
  中部: ['台中市', '彰化縣', '南投縣', '雲林縣', '苗栗縣'],
  南部: ['高雄市', '台南市', '嘉義市', '嘉義縣', '屏東縣'],
  東部: ['花蓮縣', '台東縣', '宜蘭縣'],
  離島: ['澎湖縣', '金門縣', '連江縣'],
};

const siteMap = {
  icamping: '愛露營',
  easycamp: '露營樂',
  twcamping: '台灣露營地訂位中心',
};

export default {
  name: 'CampSearch',
  data() {
    return {
      searchForm: {
        page: 1,
        limit: 10,
        keyword: '',
        area: '',
        city: '',
        useAltitude: false,
        altitude: 1000,
        altitude_min: 800,
        altitude_max: 1200,
      },
      tableData: [],
      total: 0,
      loading: false,
      cityOptions: [],
      dialogVisible: false,
      currentCamp: {},
      tagOptions: [],
      isMobile: false,
    };
  },
  async created() {
    await this.fetchData();
    await this.fetchTags();
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    getSiteName(site) {
      return siteMap[site] || site;
    },
    handleAreaChange(value) {
      this.searchForm.city = '';
      this.cityOptions = cityMap[value] || [];
    },
    handleAltitudeChange(value) {
      this.searchForm.altitude_min = Math.max(1, value - 200);
      this.searchForm.altitude_max = value + 200;
    },
    handleAltitudeSwitch(value) {
      if (!value) {
        this.searchForm.altitude = 1000;
        this.searchForm.altitude_min = 800;
        this.searchForm.altitude_max = 1200;
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        const params = { ...this.searchForm };
        if (!params.useAltitude) {
          delete params.altitude;
          delete params.altitude_min;
          delete params.altitude_max;
        }
        delete params.useAltitude;

        const response = await axios.post(API_ENDPOINTS.CAMP_SEARCH, params, {
          headers: {
            'X-Validation': getToken(params)
          }
        });
        this.tableData = response.data.camps;
        this.total = response.data.total;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.$message.error('獲取數據失敗');
      } finally {
        this.loading = false;
      }
    },
    async handleSearch() {
      this.searchForm.page = 1;
      await this.fetchData();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async handleSizeChange(val) {
      this.searchForm.limit = val;
      await this.fetchData();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    async handleCurrentChange(val) {
      this.searchForm.page = val;
      await this.fetchData();
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    showDetail(camp) {
      this.currentCamp = camp;
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.currentCamp = {};
      done();
    },
    getCampUrl(camp) {
      if (!camp.id) return null;

      switch (camp.site) {
        case 'icamping':
          return `https://m.icamping.app/store/${camp.id}`;
        case 'twcamping':
          return `https://tw-camping.tw/hotel_info.asp?hid=${camp.id}`;
        case 'easycamp':
          return `https://www.easycamp.com.tw/Store_${camp.id}.html`;
        default:
          return camp.id;
      }
    },
    getCampButtonText(site) {
      switch (site) {
        case 'icamping':
          return '愛露營';
        case 'twcamping':
          return '營地連結';
        case 'easycamp':
          return '露營樂';
        default:
          return '連結';
      }
    },
    openCampUrl(camp) {
      const url = this.getCampUrl(camp);
      if (url) {
        window.open(url, '_blank');
      }
    },
    getAltitudeDisplay(altitude) {
      if (altitude === -1) return '-';
      if (altitude === 0) return '未知';
      return altitude;
    },
    async fetchTags() {
      try {
        const response = await axios.get(API_ENDPOINTS.CAMP_TAGS);
        this.tagOptions = response.data.map((tag) => ({
          value: tag,
          label: tag,
        }));
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    },
    querySearch(queryString, callback) {
      const results = queryString
        ? this.tagOptions.filter(
          (tag) => tag.value.toLowerCase().includes(queryString.toLowerCase()),
        )
        : this.tagOptions;
      callback(results);
    },
    getUniqueTags(tags) {
      return [...new Set(tags)];
    },
    openGoogleMaps(camp) {
      const cleanAddress = camp.address.replace(/\([^)]*\)/g, '').trim();
      const cleanName = camp.name.replace(/\([^)]*\)/g, '').trim();
      const searchQuery = encodeURIComponent(cleanAddress);
      window.open(`https://www.google.com/maps/search/?api=1&query=${searchQuery}+${cleanName}`, '_blank');
    },
    getPriceRange(spaces, priceType) {
      if (!spaces || spaces.length === 0) return '未知';
      
      const prices = spaces
        .map(space => {
          return space[priceType];
        })
        .filter(price => price !== null && price !== undefined && price !== -1);

      if (prices.length === 0) return '未知';
      
      const min = Math.min(...prices);
      const max = Math.max(...prices);

      if (min === max) {
        if (min === 0) return '-';
        return `$${min}`;
      }
      return `$${min}~${max}`;
    },
    isSamePrice(spaces) {
      const weekday = this.getPriceRange(spaces, 'price_weekday');
      const weekend = this.getPriceRange(spaces, 'price_weekend');
      const holiday = this.getPriceRange(spaces, 'price_holiday');
      return weekday === weekend && weekend === holiday;
    },
  },
};
</script>

<style>
.camp-detail-dialog {
  margin-top: 5vh !important;
  max-width: 90vw;
  max-height: 90vh;
}

.camp-detail-dialog .el-dialog__body {
  max-height: calc(90vh - 60px);
  overflow-y: auto;
  padding: 20px;
}

.el-table__row {
  cursor: pointer !important;
  transition: background-color 0.3s ease;
}

.el-table__row:hover {
  background-color: #f5f7fa;
  background-color: #ecf5ff !important;
}

/* 確保按鈕和連結的點擊事件不會觸發行的點擊事件 */
.el-button,
.el-link {
  cursor: pointer;
  z-index: 1;
}
</style>

<style scoped>
.camp-search {
  padding: 10px 20px;
  font-size: 16px;
  min-height: 100vh;
}

.page-title {
  font-size: 28px;
  font-weight: bold;
  color: #303133;
  margin: 0 0 30px 0;
  text-align: center;
}

.search-card {
  margin-bottom: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

.search-form {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.el-form-item__label {
  font-size: 16px;
}

.el-input__inner,
.el-select .el-input__inner {
  font-size: 16px;
}

.el-button {
  font-size: 16px;
}

.el-table {
  font-size: 16px;
}

.el-table th {
  font-size: 16px;
  font-weight: bold;
}

.el-tag {
  font-size: 14px;
}

.el-pagination {
  font-size: 16px;
}

.el-dialog__title {
  font-size: 18px;
}

.dialog-content h4 {
  font-size: 16px;
}

.dialog-content .description-content {
  font-size: 16px;
}

.table-card {
  margin-top: 20px;
}

.pagination-container {
  margin-top: 20px;
  text-align: right;
}

.mx-1 {
  margin: 0 4px;
}

.mx-2 {
  margin: 0 8px;
}

.table-expand {
  padding: 20px;
}

.table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}

.table-expand .el-form-item__content {
  flex: 1;
}

.description-content {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  color: #606266;
}

.dialog-content {
  padding: 10px;
}

.dialog-content h4:first-child {
  margin-top: 0;
}

.dialog-content .description-content {
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  color: #606266;
  background-color: #f5f7fa;
  padding: 10px;
  border-radius: 4px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  line-height: 1.8;
}

.tag-item {
  margin: 0;
}

.keyword-input {
  width: 200px;
}

.video-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 比例 */
  height: 0;
  overflow: hidden;
}

.youtube-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.dialog-layout {
  display: flex;
  gap: 20px;
}

.dialog-left {
  flex: 0 0 40%;
  max-width: 40vw;
}

.dialog-right {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .dialog-layout {
    flex-direction: column;
  }
  
  .dialog-left {
    flex: none;
    max-width: 100%;
  }
}

.price-info {
  line-height: 1.5;
  font-size: 14px;
}

.price-info div {
  margin: 2px 0;
}

.mobile-cards {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.camp-card {
  margin-bottom: 16px;
}

.card-header {
  font-size: 18px;
  font-weight: bold;
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.card-row {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  font-weight: bold;
  color: #606266;
}

.card-actions {
  margin-top: 12px;
  text-align: right;
}

.camp-link {
  color: #409EFF;
  text-decoration: none;
}

.camp-link:hover {
  text-decoration: underline;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}

.camp-details {
  max-height: 70vh;
  overflow-y: auto;
}

.video-container iframe {
  width: 100%;
  height: 315px;
  max-width: 560px;
}

.description, .guidelines {
  margin-bottom: 20px;
}

.description h3, .guidelines h3 {
  margin-bottom: 10px;
  color: #303133;
}

.description p, .guidelines p {
  color: #606266;
  line-height: 1.6;
  white-space: pre-line;
}

@media (max-width: 768px) {
  .camp-search {
    padding: 10px;
  }

  .search-form {
    flex-direction: column;
    align-items: stretch;
  }

  .el-form-item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .video-container iframe {
    height: 200px;
  }
}
</style>
