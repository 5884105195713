/* eslint-disable max-len */
export const getToken = (p) => {
  const _0x1a2b = {...p};
  delete _0x1a2b[`${[`alt`,`itude`].join(``)}`];
  delete _0x1a2b[`${[`alt`,`itude`,`_min`].join(``)}`];
  delete _0x1a2b[`${[`alt`,`itude`,`_max`].join(``)}`];
  const _0x3c4d = Object[String.fromCharCode(0x6b,0x65,0x79,0x73)](_0x1a2b)[String.fromCharCode(0x73,0x6f,0x72,0x74)]()[String.fromCharCode(0x6d,0x61,0x70)](k => `${k}=${p[k]}`)[String.fromCharCode(0x6a,0x6f,0x69,0x6e)](`&`);
  let _0x5e6f = 0x0;
  console.log(_0x3c4d);
  for (let _0x7g8h = 0x0; _0x7g8h < _0x3c4d.length; _0x7g8h++) {
    const _0x9i0j = _0x3c4d[String.fromCharCode(0x63,0x68,0x61,0x72,0x43,0x6f,0x64,0x65,0x41,0x74)](_0x7g8h);
    _0x5e6f = ((_0x5e6f << 0x5) - _0x5e6f) + _0x9i0j;
    _0x5e6f = _0x5e6f & _0x5e6f;
  }
  return Math[String.fromCharCode(0x61,0x62,0x73)](_0x5e6f)[String.fromCharCode(0x74,0x6f,0x53,0x74,0x72,0x69,0x6e,0x67)](0x24);
}; 